<template>
    <div class="procedure_bg_warp">
        <div class="procedure-content">
            <!-- <div class="procedure_class">
                <div class="class_warp">
                    <p class="title">流程分类</p>
                    <p><a-button class="class_btn default" @click="bpmJump">BPM流程</a-button></p>
                    <p><a-button class="class_btn" @click="sfaJump">SFA流程</a-button></p>
                </div>
            </div> -->
            <div class="procedure_tabs">
                <a-tabs v-model="activeKey"  @change="callback">
                    <!-- <div slot="tabBarExtraContent"><a-icon type="eye"></a-icon></div> -->
                    <a-tab-pane key="0">
                        <div slot="tab" class="tabs_tab"> <img class="tab_img" src="@/assets/img/stay.png"> <p>我的待办</p></div>
                        <div class="pane_warp" :class="{'full_pane': fullscreen}">
                            <!-- 搜索区域 -->
                            <div class="search-wrapper">
                                <a-form layout="inline">
                                    <a-row :gutter="24">
                                        <a-col :md="10">
                                            <j-date
                                                @change="changeStartTime"
                                                v-model="queryParam.startTime"
                                                placeholder="请选择开始日期"
                                                style="width:calc(50% - 15px);"
                                            ></j-date>
                                            <span style="color:#BC0039" class="group-query-strig">&nbsp;~&nbsp;</span>
                                            <j-date
                                                @change="changeEndTime"
                                                v-model="queryParam.endTime"
                                                placeholder="请选择结束日期"
                                                style="width:calc(50% - 15px);"
                                            ></j-date>
                                        </a-col>
                                        <a-col :md="5" :sm="5">
                                            <a-input class="search_input" v-model="queryParam.workflowName"  placeholder="流程名称查询" />
                                        </a-col>
                                      <a-col :md="4" :sm="4">
                                        <a-select  class="search_input"  v-model="queryParam.processType" placeholder="请选择流程类别" style="width: 100%;" :allowClear="true">
                                          <a-select-option v-for="text in processTypeList" :key="text"  :value="text"> {{ text }}</a-select-option>
                                        </a-select>
                                      </a-col>
                                        <a-col :md="4" :sm="4">
                                            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                                              <a-button class="search_btn search_query" type="primary" @click="searchQuery">查询</a-button>
                                              <a-button class="search_btn" style="margin-left: 8px" @click="searchReset(1)">重置</a-button>
                                            </span>
                                        </a-col>
                                    </a-row>
                                    <a-row>
                                      <a-col style="marginTop: 10px; color:#bc0039;">
                                        <span>提示: 待办任务只能显示两周以内数据</span>
                                      </a-col>
                                    </a-row>
                                </a-form>
                            </div>
                            <!-- table 区域 -->
                            <div class="search-table">
                                <a-table
                                    ref="table"
                                    rowKey="url"
                                    size="middle"
                                    :customRow="rowClick"
                                    :columns="procedureColumns"
                                    :data-source="dataSource"
                                    :pagination="ipagination"
                                    :loading="loading"
                                    @change="handleTableChange"
                                    >
                                    <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
                                </a-table>
                            </div>
                            <!-- <div class="full_Screen">
                                <a-icon class="full_icon" type="fullscreen" @click="fullScreen"></a-icon>
                            </div> -->
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="1">
                        <div slot="tab" class="tabs_tab"><img class="tab_img" src="@/assets/img/flow.png"> <p>我的已办</p></div>
                        <div class="pane_warp">
                            <!-- 搜索区域 -->
                            <div class="search-wrapper">
                                <a-form layout="inline">
                                    <a-row :gutter="24">
                                        <a-col :md="10">
                                            <j-date
                                                v-model="queryParam['startTime']"
                                                placeholder="请选择开始日期"
                                                style="width:calc(50% - 15px);"
                                            ></j-date>
                                            <span style="color:#BC0039" class="group-query-strig">&nbsp;~&nbsp;</span>
                                            <j-date
                                                v-model="queryParam['endTime']"
                                                placeholder="请选择结束日期"
                                                style="width:calc(50% - 15px);"
                                            ></j-date>
                                        </a-col>
                                        <a-col :md="5" :sm="5">
                                            <a-input class="search_input" v-model="queryParam.workflowName"  placeholder="流程名称查询" />
                                        </a-col>
                                      <a-col :md="4" :sm="4">
                                        <a-select  class="search_input"  v-model="queryParam.processType" placeholder="请选择流程类别" style="width: 100%;" :allowClear="true">
                                          <a-select-option v-for="text in processTypeList" :key="text"  :value="text"> {{ text }}</a-select-option>
                                        </a-select>
                                      </a-col>
                                        <a-col :md="4" :sm="4">
                                            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                                              <a-button class="search_btn search_query" type="primary" @click="searchQuery">查询</a-button>
                                              <a-button class="search_btn" style="margin-left: 8px" @click="searchReset(2)">重置</a-button>
                                            </span>
                                        </a-col>
                                    </a-row>
                                </a-form>
                            </div>
                            <!-- table 区域 -->
                            <div class="search-table">
                                <a-table
                                    ref="table"
                                    rowKey="id"
                                    size="middle"
                                    :customRow="rowClick"
                                    :columns="doneColumns"
                                    :data-source="dataSource"
                                    :pagination="ipagination"
                                    :loading="loading"
                                    @change="handleTableChange"
                                    :rowClassName="getRowClassname1"
                                    >
                                    <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
                                </a-table>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2">
                        <div slot="tab" class="tabs_tab"><img class="tab_img" src="@/assets/img/hair.png"> <p>我的发起</p></div>
                        <div class="pane_warp" :class="{'full_pane': fullscreen}">
                            <!-- 搜索区域 -->
                            <div class="search-wrapper">
                                <a-form layout="inline">
                                    <a-row :gutter="24">
                                        <a-col :md="10">
                                            <j-date
                                                v-model="queryParam.startTime"
                                                placeholder="请选择开始日期"
                                                style="width:calc(50% - 15px);"
                                            ></j-date>
                                            <span style="color:#BC0039" class="group-query-strig">&nbsp;~&nbsp;</span>
                                            <j-date
                                                v-model="queryParam.endTime"
                                                placeholder="请选择结束日期"
                                                style="width:calc(50% - 15px);"
                                            ></j-date>
                                        </a-col>
                                        <a-col :md="5" :sm="5">
                                            <a-input class="search_input" v-model="queryParam.workflowName"  placeholder="流程名称查询" />
                                        </a-col>
                                        <a-col :md="4" :sm="4">
                                            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                                              <a-button class="search_btn search_query" type="primary" @click="searchQuery">查询</a-button>
                                              <a-button class="search_btn" style="margin-left: 8px" @click="searchReset(3)">重置</a-button>
                                            </span>
                                        </a-col>
                                    </a-row>
                                </a-form>
                            </div>
                            <!-- table 区域 -->
                            <div class="search-table">
                                <a-table
                                    ref="table"
                                    rowKey="id"
                                    size="middle"
                                    :customRow="rowClick"
                                    :columns="launchColumns"
                                    :data-source="dataSource"
                                    :pagination="ipagination"
                                    :loading="loading"
                                    @change="handleTableChange"
                                    :rowClassName="getRowClassname2"
                                    >
                                    <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
                                </a-table>
                            </div>
                            <!-- <div class="full_Screen">
                                <a-icon class="full_icon" type="fullscreen" @click="fullScreen"></a-icon>
                            </div> -->
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3">
                        <div slot="tab" class="tabs_tab"><img class="tab_img" src="@/assets/img/already.png"> <p>发起流程</p></div>
                        <a-spin :spinning="spinning">
                          <div class="spin_warp">
                            <div class="process_warp" v-for="process in processList" :key="process.appCode">
                              <div class="manage_warp">
                                <div class="manage_title"><span class="name">{{process.appName}}</span><span>{{process.dataList.length}}个</span></div>
                                <div class="manage_list">
                                    <a-row>
                                        <a-col span="6" :gutter="10" v-for="(data,index) in process.dataList" :key="index">
                                            <div class="list_li">
                                                <div class="li_avatar"><a-icon class="icon" type="pull-request" /></div>
                                                <div class="li_info" @click="processJump(data.url)">
                                                    <p class="info_name">{{data.workflowName}}</p>
                                                </div>
                                                <div class="li_con">
                                                    <a-icon v-if="process.appName =='常用流程'" @click="collection(data)" class="icon" theme="filled" type="star"></a-icon>
                                                    <a-icon v-else class="icon" type="star" @click="collection(data)"></a-icon>
                                                </div>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a-spin>
                    </a-tab-pane>
                    <a-tab-pane key="4">

                      <div slot="tab" class="tabs_tab"> <img class="tab_img bpm_flow" src="@/assets/img/bpmFlow.png"> <p>BPM流程</p></div>
                    </a-tab-pane>
                    <a-tab-pane key="5">
                      <div slot="tab" class="tabs_tab"><img class="tab_img sfa_flow" src="@/assets/img/sfaFlow.png"> <p>SFA流程</p></div>
                    </a-tab-pane>
                  <a-tab-pane key="6" v-if="sfa_other_accunts">
                    <div slot="tab" class="tabs_tab" style="position:relative;">
                      <a-badge :count="sfa_work_quantity" style="position: absolute;top: -10px;right: -10px;" show-zero></a-badge>
                      <img class="tab_img sfa_flow" src="@/assets/img/sfaFlow.png">
                        <p>SFA流程-02</p>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="7" v-if="coa_other_accunts">
                    <div slot="tab" class="tabs_tab" style="position:relative;">
                      <img class="tab_img" src="@/assets/img/stay.png">
                      <p>我的待办-02</p>
                    </div>
                    <div class="pane_warp" :class="{'full_pane': fullscreen}">
                      <!-- 搜索区域 -->
                      <div class="search-wrapper">
                        <a-form layout="inline">
                          <a-row :gutter="24">
                            <a-col :md="10">
                              <j-date
                                @change="changeStartTime"
                                v-model="queryParam.startTime"
                                placeholder="请选择开始日期"
                                style="width:calc(50% - 15px);"
                              ></j-date>
                              <span style="color:#BC0039" class="group-query-strig">&nbsp;~&nbsp;</span>
                              <j-date
                                @change="changeEndTime"
                                v-model="queryParam.endTime"
                                placeholder="请选择结束日期"
                                style="width:calc(50% - 15px);"
                              ></j-date>
                            </a-col>
                            <a-col :md="5" :sm="5">
                              <a-input class="search_input" v-model="queryParam.workflowName"  placeholder="流程名称查询" />
                            </a-col>
                            <a-col :md="4" :sm="4">
                                            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                                              <a-button class="search_btn search_query" type="primary" @click="searchQuery">查询</a-button>
                                              <a-button class="search_btn" style="margin-left: 8px" @click="searchReset(1)">重置</a-button>
                                            </span>
                            </a-col>
                          </a-row>
                          <a-row>
                            <a-col style="marginTop: 10px; color:#bc0039;">
                              <span>提示: 待办任务只能显示两周以内数据</span>
                            </a-col>
                          </a-row>
                        </a-form>
                      </div>
                      <!-- table 区域 -->
                      <div class="search-table">
                        <a-table
                          ref="table"
                          rowKey="url"
                          size="middle"
                          :customRow="rowClick"
                          :columns="procedureColumns"
                          :data-source="dataSource"
                          :pagination="ipagination"
                          :loading="loading"
                          @change="handleTableChange"
                        >
                          <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
                        </a-table>
                      </div>
                      <!-- <div class="full_Screen">
                          <a-icon class="full_icon" type="fullscreen" @click="fullScreen"></a-icon>
                      </div> -->
                    </div>
                  </a-tab-pane>
                </a-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { filterObj } from '@/utils/util';
import { USER_INFO, H3 } from "@/store/mutation-types"
import { JeecgListMixin } from '@/views/mixins/mixin'
import { getAction } from '@/api/manage'
import moment from 'moment';

const tabList = ['/mesnac/LCZX/selectDB_NEW', '/mesnac/LCZX/selectYB_NEW', '/mesnac/LCZX/selectLC']
export default {
    mixins:[JeecgListMixin],
    data () {
        return {
            processTypeList : ['全部','传阅类','审批类'],
            activeType:"",
            SfaOtherUserName:"",
            CoaOtherUserName:"",
            sfa_other_accunts:false,
            coa_other_accunts:false,
            sfa_work_quantity:0,
            fullscreen: false,
            userName: Vue.ls.get(USER_INFO).username,
            queryParam: {processType: '审批类', username: Vue.ls.get(USER_INFO).username },
            extraParam: { username: Vue.ls.get(USER_INFO).username },
            workNo:Vue.ls.get(USER_INFO).workNo,
            columns:[],
            disableMixinCreated:true,
            processList: [],
            activeKey: '0',
            spinning: false,
            procedureColumns:[
                {
                    title: '任务名称',
                    align: "center",
                    dataIndex: 'activityName'
                    // scopedSlots: { customRender: 'name' },
                },
                {
                    title: '流程名称',
                    align: "center",
                    dataIndex: 'workflowName'
                },
                {
                    width: '100px',
                    title: '发起人',
                    align: "center",
                    dataIndex: 'originatorName'
                },
                {
                    width: '100px',
                    title: '发起部门',
                    align: "center",
                    dataIndex: 'departmentName'
                },
                {
                    title: '接受时间',
                    align: "center",
                    dataIndex: 'receiveTime'
                }
            ],
            doneColumns: [
                {
                    title: '流程名称',
                    align: "center",
                    dataIndex: 'workflowName'
                },
                {
                    title: '发起人',
                    align: "center",
                    dataIndex: 'originatorName'
                },
                {
                    title: '发起时间',
                    align: "center",
                    dataIndex: 'startTime',
                },
                {
                    title: '流程状态',
                    align: "center",
                    dataIndex: 'state',
                }
            ],
            launchColumns: [
                {
                    title: '流程名称',
                    align: "center",
                    dataIndex: 'workflowName'
                },
                {
                    title: '发起时间',
                    align: "center",
                    dataIndex: 'startTime',
                },
                {
                    title: '流程状态',
                    align: "center",
                    dataIndex: 'state',
                }
            ],
            url: {
                list:'/mesnac/LCZX/selectDB_NEW'
            }
        }
    },
    created() {
      this.callback(0)
      this.queryParam.endTime = moment().format('YYYY-MM-DD')
      this.queryParam.startTime = moment().subtract('days', 800).format('YYYY-MM-DD');
      //this.queryParam.processType = '审批类'
      this.loadData()
      this.getSfaOtherAccounts()
    },
    methods: {
        moment,
        getSfaOtherAccounts(){  //判断当前登陆人是否在  sfa附属账号配置表  中
          // let username = this.extraParam.username;
          getAction('/mesnac/SfaOtherAccounts/getSfaOtherAccounts', {username:this.userName}).then(res => {
            if(res.success) {
              if (res.result.length >0){
                if (res.result[0].otheraccounts != null && res.result[0].otheraccounts != ""){
                  this.sfa_other_accunts = true;
                  this.SfaOtherUserName = res.result[0].otheraccounts;
                  this.getSfaWork();
                }
                if (res.result[0].othercoaaccounts != null && res.result[0].othercoaaccounts != ""){
                  this.coa_other_accunts = true;
                  this.CoaOtherUserName = res.result[0].othercoaaccounts;
                }
              }else {
                this.sfa_other_accunts = false;
                this.coa_other_accunts = false;
              }
            }
          })
        },
      getSfaWork(){
        getAction('/mesnac/SfaOtherAccounts/getSfaWork', {username:this.userName}).then(res => {
          if(res.success) {
            if (res.result != null && res.result.length >0){
              this.sfa_work_quantity = res.result.length;
            }else {
              this.sfa_work_quantity = 0;
            }
          }
        })
      },
        getQueryParams() {
          //获取查询条件
          let sqp = {}
          if(this.superQueryParams){
            sqp['superQueryParams']=encodeURI(this.superQueryParams)
            sqp['superQueryMatchType'] = this.superQueryMatchType
          }
          var param = Object.assign(sqp, this.queryParam, this.isorter ,this.filters);
          param.field = this.getQueryField();
          param.page = this.ipagination.current;
          param.size = this.ipagination.pageSize;
          if(this.activeType == "我的待办-02"){
            param.otherUserName = this.CoaOtherUserName;
          }else {
            param.otherUserName = "";
          }
          return filterObj(param);
        },
        get() {
          getAction('/mesnac/LCZX/selectDB', this.queryParam).then(res => {
            if(res.success) {
              let data = res.result
              if(data[0].receiveTime != this.dataSource[0].receiveTime) {
                this.loadData()
              }
            }
          })
        },
        //
        callback(key) {
            if (key == 4) {
              this.activeKey = '0'
              this.callback('0')
              this.bpmJump()
              return
            }
            if (key == 5) {
              this.activeKey = '0'
              this.callback('0')
              this.sfaJump()
              return
            }
            if (key == 6) {
              this.activeKey = '0'
              this.callback('0')
              this.sfaJump(key)
              return
            }
            if (key == 0) {
              this.activeType = "我的待办";
              let _this = this
              window.int = setInterval(() => {
                setTimeout(_this.get(), 0)
              }, 600000)
            }else {
              if( window.int != 'undefined'){  window.clearInterval(window.int);}
            }
            if (key !=3) {
              if (key == 7){
                this.activeType = "我的待办-02";
                key = 0;
              }
              let list = tabList[key]
              console.log('list: ', list);
              this.url.list = tabList[key]
              this.loadData()
              this.getSfaWork();
            } else {
              this.spinning= true
                getAction('/mesnac/LCZX/selectFQ', {username: this.userName}).then(res => {
                    if(res.success) {
                        this.processList = res.result
                        this.spinning = false
                    }
                })
            }
        },
        getProcess() {
          this.spinning= true
          getAction('/mesnac/LCZX/selectFQ', {username: this.userName}).then(res => {
            if(res.success) {
              this.processList = res.result
              this.spinning = false
            }
          })
        },
        rowClick(record) {
            var that = this;
            return {
                on:{
                    click:() => {
                        var tempwindow=window.open('_blank');
                        tempwindow.location=record.url;
                        console.log(that.activeKey)
                        this.dataSource.forEach(function (item,value,array) {
                            if(item.workflowName==record.workflowName&&that.activeKey=='0'){
                              console.log(value);
                              array.splice(value,1);
                              value = value+1;
                              console.log(value);
                            }
                        })
                        // for(var i = 0;i<this.dataSource.length;i++){
                        //   if(this.dataSource[i].workflowName==record.workFlowName){
                        //     this.dataSouce
                        //   }
                        // }
                    }
                }
            }
        },
        // 流程跳转
        processJump(url){
            var tempwindow=window.open('_blank');
            tempwindow.location=url;
        },
        // SFA流程跳转
        sfaJump(key) {
            let accuntusername = "";
            if (key == 6){
              accuntusername = this.SfaOtherUserName;
            }else {
              accuntusername = this.userName;
            }
            getAction('/mesnac/DD/getSFAURL', {username: accuntusername}).then(res => {
                if(res.success) {
                    var tempwindow=window.open('_blank');
                    tempwindow.location=res.result;
                }
            })
        },
        //SFA附属账号跳转
        sfaOtherAccuntsJump() {
          getAction('/mesnac/DD/getSFAURL', {username: this.SfaOtherUserName}).then(res => {
            if(res.success) {
              var tempwindow=window.open('_blank');
              tempwindow.location=res.result;
            }
          })
        },
        changeStartTime(val){
          this.queryParam.startTime = val
        },
        changeEndTime(val){
          this.queryParam.endTime = val
        },
        // BPM流程跳转
        bpmJump() {
            let url = Vue.ls.get(H3)
            if(url) {
              var tempwindow=window.open('_blank');
              tempwindow.location=url;
            } else {
              this.$message.warn('您没有该系统的账号,无法登录')
            }
        },
        fullScreen() {
            this.fullscreen = true
            console.log('this.fullscreen: ', this.fullscreen);
        },
        // 流程收藏
        collection(data) {
          let formData ={
            employeeNo: this.workNo,
            type:data.favorite,
            url:data.url,
            workFlowCode:data.workflowCode
          }
          getAction('/mesnac/CYLC/changeFrequency', formData).then(res => {
            if(res.success) {
              this.$message.success(res.message)
              this.getProcess()
            } else {
              this.$message.warn(res.message)
            }
          })
        },
        // 重置
        searchReset() {
          this.queryParam = {}
          this.queryParam.username = this.userName
          this.queryParam.processType = '审批类'
          this.loadData()
          // if(num == 1) {
          //   this.loadData(1)
          // }
          // if(num == 2) {

          // }
        },
      getRowClassname1(record){
        if(record.state == '运行中'){
          return 'rawColor-yxz';
        }else if(record.state == '已完成'){
          return 'rawColor-ywc';
        }else if(record.state == '已取消'){
          return 'rawColor-yqx';
        }else{
          return 'rawColor-other';
        }
      },
      getRowClassname2(record){
        if(record.state == '运行中'){
          return 'rawColor-yxz';
        }else if(record.state == '已完成'){
          return 'rawColor-ywc';
        }else if(record.state == '已取消'){
          return 'rawColor-yqx';
        }else{
          return 'rawColor-other';
        }
      }
    }
}
</script>

<style lang="less" scoped>
/deep/.ant-table-tbody .rawColor-yxz{
   background-color: #DDFFF5 !important;
}
/deep/.ant-table-tbody .rawColor-ywc{
   background-color: #FFFF99 !important;
}
/deep/.ant-table-tbody .rawColor-yqx{
   background-color: #F0A29B !important;
}
/deep/.ant-table-tbody .rawColor-other{
   background-color: #FFFFFF !important;
}
.table-page-search-submitButtons {
  display: block;
  // margin-bottom: 24px;
  height: 100%;
  white-space: nowrap;
}
.procedure_bg_warp{
    width: 100%;
    // background: url("~@/assets/img/home_bg.png") no-repeat top center / cover;
    background: #f6f6f6;
    background-size: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    .procedure-content{
        width: 1600px;
        margin: 0 auto;
        // margin-top: 40px;
        display: flex;
        background: rgba(255, 246, 246, 1);
        .procedure_class{
            width: 230px;
            .class_warp {
                padding:30px 15px;
                .title{
                    color: rgba(16, 16, 16, 100);
                    font-size: 22px;
                }
                .class_btn {
                    width: 200px;
                    height: 40px;
                    text-align: center;
                    font-size: 18px;
                    border: 1px solid rgba(188, 0, 57, 100);
                    border-radius: 10px;
                    color: rgba(16, 16, 16, 82);
                    &:hover {
                        color: #BC0039;
                    }
                    &.default{
                      color: #BC0039;
                      background-color: #FFECEC;
                    }
                }
            }
        }
        .procedure_tabs{
            // border-left: 1px solid rgba(189, 0, 46, 100);
            width: 1600px;
            min-height: 900px;
            background: #fff;
            /deep/.ant-tabs{
                .ant-tabs-bar{
                    // height: 124px;
                    border-bottom: 15px solid #fff6f6;
                    .ant-tabs-nav-container{
                        height: 124px;
                        .ant-tabs-nav-wrap{
                            height: 124px;
                            .ant-tabs-nav{
                                height: 124px;
                                margin-left:50px;
                                .ant-tabs-tab{
                                    width: 120px;
                                    height: 100px;
                                    margin: 12px 26px 12px 0;
                                    padding:5px 0px;
                                    border: 1px solid rgba(229, 230, 230, 100);
                                    // background-color: rgba(255, 236, 236, 16);
                                    background-color: rgba(255, 252, 252, 1);
                                    // transform: translateY(50%);
                                    .tabs_tab {
                                        text-align: center;
                                        .tab_img {
                                          width: 60px;
                                        }
                                        .bpm_flow{
                                          width: 60px;
                                          padding: 10px;
                                        }
                                        .sfa_flow{
                                          width: 60px;
                                          padding: 10px;
                                        }
                                        .tab_icon {
                                            margin: 0;
                                            font-size: 35px;
                                            margin-bottom: 12px;
                                        }
                                    }
                                }
                                .ant-tabs-ink-bar {
                                    display: none !important;
                                }
                                .ant-tabs-tab-active{
                                    background-color: rgba(255, 236, 236, 16);
                                    border: 1px solid rgba(188, 0, 57, 100);
                                    color: #BC0039;
                                    .tabs_tab {
                                      p{
                                        color: #BC0039;
                                      }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .pane_warp{
                min-height: 600px;
                position: relative;
                .full_Screen{
                    position: absolute;
                    top: 0px;
                    right: 13px;
                    width: 30px;
                    height: 30px;
                    .full_icon{
                        color: #BC0039;
                        cursor: pointer;
                    }
                }
                // border-top: 15px solid #fff6f6;
                .search-wrapper{
                    margin-left: 40px;
                    /deep/.ant-calendar-picker{
                        .ant-calendar-picker-input{
                            height: 40px;
                            border: 1px solid rgba(188, 0, 57, 100);
                            border-radius: 10px;
                            color: rgba(188, 0, 57, 100);
                            font-size: 16px;
                            &::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
                                color:rgba(188, 0, 57, 100);
                                font-size: 16px;
                            }
                        }
                        .anticon {
                            color: #BC0039;
                        }
                    }
                    .search_input {
                        height: 40px;
                        border: 1px solid rgba(188, 0, 57, 100);
                        border-radius: 10px;
                        color: rgba(188, 0, 57, 100);
                        font-size: 16px;
                        &::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
                            color:rgba(188, 0, 57, 100);
                            font-size: 16px;
                        }
                    }
                    .search_btn {
                        width: 67px;
                        height: 40px;
                        text-align: center;
                        font-size: 18px;
                        line-height: 40px;
                        color: #BC0039;
                        border: 1px solid #BC0039;
                        border-radius: 10px;
                        padding: 0px;
                    }
                    .search_query{
                        background-color: #ffeded;
                    }
                }
                .search-table {
                    width: 1500px;
                    margin: 0 auto;
                    margin-top: 20px;
                    /deep/.ant-table {
                      .ant-table-thead > tr > th{
                        color: #101010;
                      }
                      color: #101010;
                    }
                    /deep/.ant-table-placeholder {
                      border-bottom: 1px solid #bc0039;
                      border-top: 1px solid #bc0039;
                    }
                    /deep/.ant-pagination {
                        .ant-pagination-next, .ant-pagination-prev {
                            .ant-pagination-item-link{
                                &:hover{
                                    color: #BC0039;
                                }
                            }
                        }
                        .ant-pagination-next:focus .ant-pagination-item-link{
                            color: #BC0039;
                        }
                        .ant-pagination-item{
                            a:hover{
                                color: #BC0039;
                            }
                        }
                        .ant-pagination-item-active{
                            border-color: #BC0039;
                            a{color: #BC0039;}
                        }
                    }
                    /deep/.ant-table-tbody{
                      tr {
                        cursor: pointer;
                      }
                    }
                }
            }
            .full_pane {
                z-index: 10;
                width: 100%;
                background: #fff;
            }
            .spin_warp{
              min-height: 500px;
            }
            .process_warp {
              width: 100%;
                .manage_warp{
                    margin-left: 25px;
                    .manage_title{
                        font-size: 14px;
                        height: 20px;
                        color: rgba(16, 16, 16, 100);
                        position: relative;
                        &::after{
                            content: "";
                            display: inline-block;
                            width: 5px;
                            height: 20px;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: #BC0039;
                        }
                        .name {
                            margin: 0 30px;
                        }
                    }
                    .manage_list{
                        padding-top: 20px;
                        .list_li{
                            display: flex;
                            padding-top: 14px;
                            width: 280px;
                            height: 80px;
                            position: relative;
                            margin-right: 35px;
                            margin-bottom:20px;
                            background-color: rgba(244, 244, 244, 1);
                            cursor: pointer;
                            .li_avatar{
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;
                                // background-color: #BC0039;
                                text-align: center;
                                line-height: 56px;
                                margin-right: 10px;
                                overflow: hidden;
                                margin-left: 10px;
                                img{
                                  width: 100%;
                                  height: 100%;
                                }
                                .icon{
                                    color: #BC0039;
                                    font-size: 35px;
                                }
                            }
                            .li_info{
                                p{
                                    padding: 0px;
                                    margin: 0px;
                                    color: #101010 10000%;
                                }
                                .info_name{
                                    width: 180px;
                                    color: #101010;
                                    font-size: 16px;
                                    margin-bottom: 10px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; //行数
                                    -webkit-box-orient: vertical;

                                }
                                .info_num{
                                    font-size: 14px;
                                }
                            }
                            .li_con{
                                position: absolute;
                                top:-21px;
                                right: -21px;
                                width: 42px;
                                height: 42px;
                                transform: rotate(45deg);
                                text-align: center;
                                background: #fff;
                                z-index: 20;
                                .icon{
                                    color: #BC0039;
                                    font-size: 12px;
                                    position: absolute;
                                    bottom: 3px;
                                    left: 18px;
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>


